import * as React from "react";
import { TextField, Edit, SimpleForm, NumberInput, required } from 'react-admin';

export const StockEdit = (props: any) => {

    const init = { date: new Date() }

    return <Edit title="Stock" {...props} undoable={false}>
        <SimpleForm initialValues={init}>
            <TextField label="Mail" source="email" />
            <TextField label="年度" source="year" />
            <NumberInput label="年度有休" source="stocks" validate={[required()]} />
            <NumberInput label="前年度繰越" source="carryForward" validate={[required()]} />
        </SimpleForm>
    </Edit>
}