import * as React from "react";
import { Edit, SimpleForm, DateInput, TextInput, required, BooleanInput } from 'react-admin';

export const AdminEmployeeEdit = (props: any) => {

    return <Edit {...props} undoable={false}>
        <SimpleForm >
            <TextInput label='名前' source="name" validate={required()} />
            <TextInput label='email' source="email" validate={required()} />
            <DateInput label='入社日付' source="joinedAt" validate={required()} />
            <BooleanInput label="管理者" source="isAdmin" />
        </SimpleForm>
    </Edit>
}