import React, { useEffect, useState } from 'react'
import {
  Admin, Resource, ListGuesser
} from 'react-admin';
import './App.css';
import { authProvider } from './firebase-init'
import myDataProvider from './MyDataProvider'
import { app } from './firebase-init'

import { CustomLoginPage } from './Login'

import * as Kintai from './kintai'
import * as Stock from './admin/stock'
import { PaidList } from './dashboard'
import * as AdminKintai from './admin/kintai'
import * as AdminEmployee from './admin/employee'

// import { IkouCreate } from './ikou/ikou-create';


import { Employee } from './i-api-ra'

import { createBrowserHistory as createHistory } from 'history';

import japaneseMessages from '@bicstone/ra-language-japanese';
import polyglotI18nProvider from 'ra-i18n-polyglot';

interface I18nMessages {
  [name: string]: any
}
const messages: I18nMessages = {
  ja: japaneseMessages,
};
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'ja');

const history = createHistory();

// 本番と開発でAPIのURLを変える
const fUrl = /localhost/.test(global.location.hostname) ? 'http://localhost:5001/mosa-kintai-reboot/us-central1/api' : 'https://us-central1-mosa-kintai-reboot.cloudfunctions.net/api'
const dataProvider = myDataProvider(fUrl)

const api = app.functions().httpsCallable('api')

const App = () => {
  const [user, setUser] = useState<Employee>()

  if (!user) {
    history.replace('/login')
  }
  useEffect(() => {
    if (user) return
    authProvider.getPermissions({}).then(async (userInfo) => {
      if (!userInfo) return
      const options = {
        resource: 'admin/roles',
        method: 'GET',
        query: { id: userInfo?.email }
      }
      const user = await api({ url: '/admin/roles', ...options })
      setUser(user.data?.data || {})
      userInfo.isAdmin = user.data?.data.isAdmin
      if (global.location.pathname === '/undefined') {
        history.replace('/kintai')
      }
    })
  })

  const checkDomain = (user: (Employee | undefined)): boolean => {
    if (!user) return false
    return /@mosa-architect.com$/.test(user?.email)
  }

  return <React.StrictMode>
    <Admin
      history={history}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={CustomLoginPage}
    >

      {[
    //     // 移行用
    //     user?.isAdmin ? <Resource name="ikou"
    //     list={ListGuesser}
    //     create={IkouCreate}
    // /> : <></>,
        user?.isAdmin ?
          <Resource name='admin/kintai'
            options={{ label: '勤怠実績一覧' }}
            list={AdminKintai.AdminKintaiList}
            create={AdminKintai.AdminKintaiCreate}
            edit={AdminKintai.AdminKintaiEdit}
            key='1'
          />
          : <></>,
        user?.isAdmin ?
          <Resource name="stock"
            options={{ label: '有休管理' }}
            list={Stock.StockList}
            create={Stock.StockCreate}
            edit={Stock.StockEdit}
            key='3'
          />
          : <></>,
        user?.isAdmin ?
          <Resource name='admin/employee'
            options={{ label: '社員マスタ' }}
            list={AdminEmployee.AdminEmployeeList}
            create={AdminEmployee.AdminEmployeeCreate}
            edit={AdminEmployee.AdminEmployeeEdit}
            key='2'
          />
          : <></>,
        checkDomain(user) ? <Resource name="paid"
          options={{ label: '有休消化状況' }}
          list={PaidList}
        /> : <></>,

        checkDomain(user) ? <Resource name="kintai"
          options={{ label: '勤怠管理' }}
          list={Kintai.KintaiList}
          create={Kintai.KintaiCreate}
          edit={Kintai.KintaiEdit}
        /> : <></>,
      ]}
    </Admin >
  </React.StrictMode>
}

export default App;