import { stringify } from 'query-string';
import {
    DataProvider,
    // HttpError
} from 'ra-core';
import { app } from './firebase-init'

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */


// カスタムヘッダなどの共通処理
const api = app.functions().httpsCallable('api')
const httpClient = async (url: string, options: { resource: string, body?: any, method?: string, query?: {}, headers?: {} }) => {
    try {
        const result = await api({ url, ...options })
        return result.data
    } catch (e) {
        throw errorHandler(e)
    }
};

// エラーハンドラ
const errorHandler = (e: Error): Error => {
    console.error('error handling', e)
    return e
}

export default function MyDataProvider(
    apiUrl: string,
    countHeader: string = 'Content-Range'
): DataProvider {
    return {
        getList: (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;

            const rangeStart = (page - 1) * perPage;
            const rangeEnd = page * perPage - 1;

            const url = `/${resource}`;
            const query = {
                sort: [field, order],
                range: countHeader === 'Content-Range' ? [] : [rangeStart, rangeEnd],
                filter: params.filter,
            };
            const options = {
                resource,
                method: 'GET',
                query,
            }
            return httpClient(url, options).then((json) => {
                if (!countHeader) {
                    throw new Error(
                        `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                    );
                }
                return {
                    data: json.list,
                    total: countHeader === 'Content-Range' ? json.pageCount : json.pageCount
                };
            });
        },

        getOne: (resource, params) => {
            const options = { resource, method: 'GET', query: { id: params.id } }
            return httpClient(`/${resource}`, options).then((json) => {
                return { data: json.data }
            })
        },

        getMany: (resource, params) => {
            const options = { resource, method: 'GET-MANY', query: { ids: params.ids } }
            const url = `/${resource}`;
            return httpClient(url, options).then((json) => ({ data: json.data }));
        },

        getManyReference: (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;

            const rangeStart = (page - 1) * perPage;
            const rangeEnd = page * perPage - 1;

            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            const url = `/${resource}?${stringify(query)}`;
            const options =
                countHeader === 'Content-Range'
                    ? {
                        resource,
                        // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                        headers: new Headers({
                            Range: `${resource}=${rangeStart}-${rangeEnd}`,
                        }),
                    }
                    : { resource };

            return httpClient(url, options).then(({ headers, json }) => {
                if (!headers.has(countHeader)) {
                    throw new Error(
                        `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                    );
                }
                return {
                    data: json,
                    total:
                        countHeader === 'Content-Range'
                            ? parseInt(
                                headers.get('content-range')!!.split('/').pop()!!,
                                10
                            )
                            : parseInt(headers.get(countHeader.toLowerCase())!!),
                };
            });
        },

        update: (resource, params) =>
            httpClient(`/${resource}`, {
                resource,
                method: 'PUT',
                query: { id: params.id },
                body: params,
            }).then((json) => ({ data: json })),

        // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
        updateMany: (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    httpClient(`/${resource}/${id}`, {
                        resource,
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({ data: responses.map((json) => json.id) })),

        create: (resource, params) =>
            httpClient(`/${resource}`, {
                resource,
                method: 'POST',
                body: params.data
            }).then((json) => ({
                data: { ...params.data, id: json.id },
            })),

        delete: (resource, params) =>
            httpClient(`/${resource}/${params.id}`, {
                resource,
                method: 'DELETE',
                query: { id: params.id },
            }).then((json) => ({ data: json })),

        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    httpClient(`/${resource}/${id}`, {
                        resource,
                        method: 'DELETE',
                        query: { id },
                        headers: new Headers({
                            'Content-Type': 'text/plain',
                        }),
                    })
                )
            ).then(responses => ({
                data: responses.map((json) => json.id),
            })),
    }
};