import * as React from "react";
import { List, Datagrid, TextField, NumberField, ReferenceField, EditButton } from 'react-admin';
import { PaidViewOption } from '../../i-api-ra'
import { StockFilter } from './stock-filter'
export const StockList = (props: any) => (
  <List {...props} filters={<StockFilter />} pagination={false} filterDefaultValues={{ year: new Date().getFullYear() }} bulkActionButtons={false}>
    <Datagrid>
      <TextField sortable={false} label="年度" source="year" />
      <ReferenceField sortable={false} label="名前" source='email' reference='admin/employee'>
        <TextField source="name" />
      </ReferenceField>
      <NumberField sortable={false} label="年度有休" source="stocks" options={PaidViewOption} />
      <NumberField sortable={false} label="前年度繰越" source="carryForward" options={PaidViewOption} />
      <NumberField sortable={false} label="取得" source="paid" options={PaidViewOption} />
      <NumberField sortable={false} label="有休残り" source="remain" options={PaidViewOption} />
      <EditButton />
    </Datagrid>
  </List >
)
