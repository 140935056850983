import * as React from "react";
import { ReferenceField, List, Datagrid, TextField, DateField, SelectField, EditButton } from 'react-admin';
import { kintaiTypes } from '../../kintai/kintai-const'
import { AdminKintaiFilter } from './admin-kintai-filter'
export const AdminKintaiList = (props: any) => (
  <List {...props} filters={<AdminKintaiFilter />} pagination={false} filterDefaultValues={{ year: new Date().getFullYear() }} bulkActionButtons={false}>
    <Datagrid>
      <DateField sortable={false} label='日付' source="date" options={{ weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' }} />
      <ReferenceField sortable={false} label="名前" source='email' reference='admin/employee'>
        <TextField source="name" />
      </ReferenceField>
      <SelectField sortable={false} label='勤怠種別' source="type" choices={kintaiTypes} />
      <TextField sortable={false} label='コメント' source="comment" />
      <EditButton />
    </Datagrid>
  </List>
)
