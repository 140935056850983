import * as React from "react";
import { List, Datagrid, TextField, DateField, SelectField, EditButton, useListContext } from 'react-admin';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';

import { kintaiTypes } from './kintai-const'
import { KintaiFilter } from './kintai-filter'
import { useMediaQuery, Theme } from '@material-ui/core';

const cardStyle = { margin: '1em' }

const dateFormatter = new Intl.DateTimeFormat('ja-JP', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' })
const DateView = (props: any) => {
  const { value } = props
  let v = ''
  if (value) {
    v = dateFormatter.format(new Date(value).getTime())
  }
  return <span>{v}</span>
}

const KintaiCard = (props: any) => {
  const { ids, data, basePath } = useListContext();
  return <>
    {ids.map(id => {
      const record = data[id]
      return <Card style={cardStyle}>
        <CardContent>
          <Grid container>
            <Grid xs={12}>
              <div style={{ float: "right" }}>
                <EditButton resource='kintai' basePath={basePath} record={record} label="" />
              </div>
              <Typography variant="subtitle1"><DateView value={record.date} /></Typography>
              <Typography variant="body2">
                <SelectField label='勤怠種別' record={record} source="type" choices={kintaiTypes} />
                <span> : </span>
              </Typography>
              <Typography><TextField label='コメント' record={record} source="comment" /></Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    })}
  </>
}

export const KintaiList = (props: any) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return <List
    pagination={false}
    {...props} filters={<KintaiFilter />}
    filterDefaultValues={{ year: new Date().getFullYear() }}
    bulkActionButtons={false}>
    {isSmall ? <KintaiCard /> : <Datagrid rowClick="edit">
      <DateField sortable={false} label='日付' source="date" options={{ weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' }} />
      <SelectField sortable={false} label='勤怠種別' source="type" choices={kintaiTypes} />
      <TextField sortable={false} label='コメント' source="comment" />
    </Datagrid>}
  </List>
}
