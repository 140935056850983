import * as React from "react";
import { List, Datagrid, useListContext, NumberField, TextField } from 'react-admin';
import { Card, CardContent, Typography } from '@material-ui/core';

import { useMediaQuery, Theme } from '@material-ui/core';
import { PaidViewOption, } from '../i-api-ra'
import '@fontsource/roboto';

const cardStyle = { margin: '1em' }
const formatter = new Intl.NumberFormat('ja-JP', { minimumFractionDigits: 1 })

const NumberView = (props: any) => {
  const { value } = props
  let v = '-'
  if (value) {
    v = formatter.format(value)
  }
  return <span>{v}</span>
}

const PaidCard = (props: any) => {
  const { ids, data } = useListContext();
  return <>
    {ids.map(id => {
      const record = data[id]

      return <Card key={id} style={cardStyle}>
        <CardContent key={id} style={cardStyle}>
          <Typography variant='subtitle1'>{record.year}年度</Typography>
          <Typography variant='body2'>
            有休総数: <NumberView value={record.stocks + record.carryForward} />
            (有休:<NumberView value={record.stocks} /> / 繰越:<NumberView value={record.carryForward} />)
            <br />
            消化:<NumberView value={record.paid} /> / 残り:<NumberView value={record.remain} />
          </Typography>
        </CardContent>
      </Card>
    })}
  </>
}

const PaidList = (props: any) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return <List {...props} bulkActionButtons={false} pagination={false} >
    {isSmall ? <PaidCard /> : <Datagrid>
      <TextField sortable={false} label="年度" source="year" />
      <NumberField sortable={false} label="有休" source="stocks" options={PaidViewOption} />
      <NumberField sortable={false} label="繰越" source="carryForward" options={PaidViewOption} />
      <NumberField sortable={false} label="消化" source="paid" options={PaidViewOption} />
      <NumberField sortable={false} label="残り" source="remain" options={PaidViewOption} />
    </Datagrid>
    }
  </List >
}

export {
  PaidList
}