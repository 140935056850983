import * as React from "react";
import { Create, ReferenceInput, SelectInput, SimpleForm, required, NumberInput } from 'react-admin';

export const StockCreate = (props: any) => {

    const init = { year: new Date().getFullYear(), stocks: 0, carryForward: 0 }

    return <Create title="Stock" {...props}>
        <SimpleForm initialValues={init}>
            <ReferenceInput label='名前' source='email' reference='admin/employee'>
                <SelectInput optionText='name' validate={[required()]} />
            </ReferenceInput>
            <NumberInput label="年度" source="year" validate={[required()]} />
            <NumberInput label="年度有休" source="stocks" validate={[required()]} />
            <NumberInput label="前年度繰越" source="carryForward" validate={[required()]} />
        </SimpleForm>
    </Create>
}