import * as React from "react";
import { Login } from "react-admin";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
// import ForgotPasswordButton from './CustomForgotPassword'

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/kintai',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ]
};

const firebaseAuth = firebase.auth()

export const CustomLoginPage = (props: any) => (
  <Login {...props}>
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />;
  </Login>
);
