import * as React from "react";
import { Filter, SelectInput, ReferenceInput, required } from 'react-admin';

const start = 2018
const thisYear = new Date().getFullYear()
const nextYear = thisYear + 1

const choises = new Array<{ id: number, name: string }>()

for (let y = start; y <= nextYear; y++) {
  choises.push({ id: y, name: `${y}` })
}

export const AdminKintaiFilter = (props: {}) => {
  return <Filter {...props}>
    <SelectInput options={{ label: '年度' }} choices={choises} source="year" defaultValue={thisYear}></SelectInput>
    <ReferenceInput label='名前' source='email' reference='admin/employee'>
      <SelectInput optionText='name' validate={[required()]} />
    </ReferenceInput>
  </Filter>
}