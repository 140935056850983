export const PaidViewOption = { minimumFractionDigits: 1 }

// 社員情報
export interface Employee {
  id?: string
  email: string
  name: string,
  joinedAt: string      // 入社日(yyyy-MM-dd)
  joinedAtTime: number  // 入社日の数字表現
  isAdmin: boolean
}


// 勤怠実績
export interface Kintai {
  id?: string         // レコードindex
  email: string       // 対象者email
  name: string        // 登録時の名前
  date: string        // 有給取得日/休出日
  dateTime: number    // date->longに変換した検索用キー
  type?: string       // 勤怠種別(AM休、全休など)
  comment?: string    // コメント(その他管理用)
}
