import * as React from "react";
import { List, Datagrid, TextField, DateField, EditButton, BooleanField } from 'react-admin';
export const AdminEmployeeList = (props: any) => (
  <List {...props} pagination={false} bulkActionButtons={false}>
    <Datagrid>
      <TextField sortable={false} label='名前' source="name" />
      <TextField sortable={false} label='email' source="email" />
      <BooleanField sortable={false} label="管理者" source="isAdmin" />
      <DateField sortable={false} label='入社日付' source="joinedAt" />
      <EditButton />
    </Datagrid>
  </List>
)
