import * as React from "react";
import { Create, SimpleForm, DateInput, TextInput, BooleanInput, required, } from 'react-admin';

const init = { isAdmin: false }

export const AdminEmployeeCreate = (props: any) => {

    return <Create title="社員登録" {...props}>
        <SimpleForm initialValues={init}>
            <TextInput label='名前' source="name" validate={required()} />
            <TextInput label='email' source="email" validate={required()} />
            <DateInput label='入社日付' source="joinedAt" validate={required()} />
            <BooleanInput label="管理者" source="isAdmin" />
        </SimpleForm>
    </Create>
}