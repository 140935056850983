import * as React from "react";
import { kintaiTypes } from '../../kintai/kintai-const'
import { Edit, SimpleForm, DateInput, SelectInput, TextInput, ReferenceInput, required, } from 'react-admin';

export const AdminKintaiEdit = (props: any) => {

    return <Edit title="KINTAI" {...props} undoable={false}>
        <SimpleForm >
            <ReferenceInput source='email' reference='admin/employee'>
                <SelectInput optionText='name' validate={[required()]} />
            </ReferenceInput>
            <DateInput label='日付' source="date" validate={[required()]} />
            <SelectInput label='勤怠種別' source="type" choices={kintaiTypes} defaultValue='FULL' validate={[required()]} />
            <TextInput multiline label='コメント' source="comment" />
        </SimpleForm>
    </Edit>
}