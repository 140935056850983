import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase';
import firebase from 'firebase'

export const config = {
  apiKey: "AIzaSyDVhVwjDRsvE4MbwcSTetsCwwm-xuqIGLw",
  authDomain: "mosa-kintai-reboot.firebaseapp.com",
  databaseURL: "https://mosa-kintai-reboot-default-rtdb.firebaseio.com",
  projectId: "mosa-kintai-reboot",
  storageBucket: "mosa-kintai-reboot.appspot.com",
  messagingSenderId: "1061654305509",
  appId: "1:1061654305509:web:548796d7c8aba6ce3a920c",
  measurementId: "G-B7NXPF5DEB"
};

export const app = firebase.initializeApp(config)

if (global.location.hostname === 'localhost') {
  app.functions().useEmulator('localhost', 5001)
}

// All options are optional
const options = {
  app,
}

export const dataProvider = FirebaseDataProvider(config, options);
export const authProvider = FirebaseAuthProvider(config, options);
// export const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);
