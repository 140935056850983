import * as React from "react";
import { kintaiTypes } from '../../kintai/kintai-const'
import { Create, SimpleForm, DateInput, SelectInput, TextInput, ReferenceInput, required, } from 'react-admin';

export const AdminKintaiCreate = (props: any) => {

    const init = { date: new Date(), type: 'FULL' }

    return <Create title="KINTAI" {...props}>
        <SimpleForm initialValues={init}>
            <ReferenceInput source='email' reference='admin/employee'>
                <SelectInput optionText='name' validate={[required()]} />
            </ReferenceInput>
            <DateInput label='日付' source="date" validate={[required()]} />
            <SelectInput label='勤怠種別' source="type" choices={kintaiTypes} validate={[required()]} />
            <TextInput multiline label='コメント' source="comment" />
        </SimpleForm>
    </Create>
}