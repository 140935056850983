import * as React from "react";
import { kintaiTypes } from './kintai-const'
import { Edit, SimpleForm, DateInput, SelectInput, TextInput, required } from 'react-admin';

export const KintaiEdit = (props: any) => {

    const init = { date: new Date() }

    return <Edit title="KINTAI" undoable={false} {...props}>
        <SimpleForm initialValues={init}>
            <DateInput label='日付' source="date" validate={[required()]} />
            <SelectInput label='勤怠種別' source="type" choices={kintaiTypes} validate={[required()]} />
            <TextInput multiline label='コメント' source="comment" />
        </SimpleForm>
    </Edit>
}